import axios from '@/axios/globalaxios'

export const getTableList = data =>{
    return axios({
        url: "/tradelab/marketPortfolioManagement/findPortfolioManagementByAcidAndType",
        method: 'post',
        data:data
    })
}
export const findFundTrendByAcid = data =>{
    return axios({
        url: "/tradelab/marketPortfolioManagement/findFundTrendByAcid",
        method: 'post',
        data:data
    })
}
export const findIndexByAcidAndFid = data =>{
    return axios({
        url: "/tradelab/marketPortfolioManagement/findIndexByAcidAndFid",
        method: 'post',
        data:data
    })
}