import {
  getTableList,
  findFundTrendByAcid,
  findIndexByAcidAndFid,
} from "./groupApi";

export const tagChanges = (item, data) => {
  data.tagType = item.key;
  getGroupTables(data);
};
// 获取 table 数据
export const getGroupTables = (data) => {
  data.loading = true;
  let param = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    type: data.tagType,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  getTableList(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = dealList(newRes.portfolioManagements.list);
      data.total = newRes.portfolioManagements.total;
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};
// 格式化数据
export const dealList = (data) => {
  for (let i in data) {
    data[i].direction = data[i].direction === 0 ? "买入" : "卖出";
  }
  return data;
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getGroupTables(data);
};
// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getGroupTables(data);
};
//  资产走势
export const trendCharts = (data) => {
  let date = [];
  let value = [];
  findFundTrendByAcid({
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid
  }).then((res) => {
    if (res.data.code === "200") {
      let arr = res.data.data.fundTrendInformations;
      for (let i in arr) {
        date.push(arr[i].date);
        value.push(arr[i].netasset);
      }
      data.option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: date,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: value,
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#FEAE10",
            },
            itemStyle: {
              color: "#FEAE10",
            },
          },
        ],
      };
    }
  });
};

//  指标
export const groupIndexs = (data) => {
  findIndexByAcidAndFid({
    acid: JSON.parse(sessionStorage.getItem("acid")),
    benchmark: "SZ50",
  }).then((res) => {
    if (res.data.code === "200") {
      let newRew = res.data.data.fundTrendInformations[0];
      let informationRatio = newRew.informationRatio
        ? newRew.informationRatio
        : 0;
      let jensenMeasure = newRew.jensenMeasure ? newRew.jensenMeasure : 0;
      let sharpeRatio = newRew.sharpeRatio ? newRew.sharpeRatio : 0;
      let tenorRatio = newRew.tenorRatio ? newRew.tenorRatio : 0;
      data.normItem = [
        sharpeRatio,
        jensenMeasure,
        tenorRatio,
        informationRatio,
      ];
    }
  });
};
